<template>
  <div :style="{ color: titleColor }">
    <p class="mt-10 mb-0 p-0" style="font-size: 12px">
      เปอร์เซ็นชนะ: {{ winPercent.toFixed(2) }}%
    </p>
    <meter
      class="m-0"
      :value="winPercent"
      optimum="100"
      max="100"
      style="height: 10px; width: 100%"
    ></meter>
  </div>
</template>

<script>
export default {
  props: {
    winPercent: {
      type: Number,
    },
    titleColor: {
      type: String,
      default: "",
    },
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {},
};
</script>
<style>
meter::-webkit-meter-optimum-value {
  background: #ffae00;
}
meter::-moz-meter-bar {
  /* Firefox Pseudo Class */
  background: #ffae00;
}
</style>
