<!-- 
  This is the billing page, it uses the dashboard layout in: 
  "./layouts/Dashboard.vue" .
 -->

<template>
  <div>
    <a-collapse
      v-model="activeKey2"
      class="mb-10"
      style="
        z-index: 10;
        border: 0px;
        border-radius: 0px 0px 12px 12px;
        position: -webkit-sticky !important;
        position: sticky !important;
        top: 0 !important;
      "
    >
      <a-collapse-panel key="1" header="บอททำนายผล" style="border-bottom: 0px">
        <a-card
          :bordered="false"
          class="center text-white gold-border gold_color mb-20"
        >
          <a-row :gutter="24" type="flex" align="stretch" class="w-100">
            <a-col :span="24" :lg="12" :xl="12" :xs="12">
              <a-card :bordered="false" class="gold_color center text-white">
                โต๊ะ {{ tableId }}
              </a-card>
            </a-col>
            <a-col :span="24" :lg="12" :xl="12" :xs="12">
              <a-card :bordered="false" class="gold_color center text-white">
                <div v-if="gameRound != null">รอบที่ {{ gameRound }}</div>
                <pulse-loader color="#000" size="6px" v-else></pulse-loader>
              </a-card>
            </a-col>
            <a-col :span="24" :lg="24" :xl="24" :xs="24">
              <Meter :winPercent="winRate"></Meter>
            </a-col>

            <!-- <a-col :span="24" :lg="8" :xl="8" :xs="8" class="mb-24">
          <a-card :bordered="false" class="gold_color center text-white">
            <pulse-loader
              color="#000"
              size="6px"
              v-if="!setTimer"
            ></pulse-loader>

            <div v-else>{{ timer }}</div>
          </a-card>
        </a-col> -->

            <a-col :span="24" :lg="24" :xl="24" class="mb-24">
              <div>
                <a-card
                  v-if="gamePath === 'ROULETTE'"
                  :bordered="false"
                  class="center glow"
                  :style="{
                    'background-color': '#ffae0098',
                  }"
                >
                  <h1
                    style="font-size: 21px"
                    :class="['reveal-text m-0']"
                    v-if="gameLive.prediction != null"
                  >
                    {{ gameLive.prediction.playerType.toString() }} |
                    {{ gameLive.prediction.number }}
                  </h1>

                  <pulse-loader color="#000" size="16px" v-else></pulse-loader>
                </a-card>
                <a-card
                  v-else
                  :bordered="false"
                  class="center glow"
                  :style="{
                    'background-color':
                      gameLive.prediction === null
                        ? '#ffae0098'
                        : gameLive.prediction === 'BANKER' ||
                          gameLive.prediction === 'DRAGON'
                        ? '#d21515'
                        : '#079300'
                        ? gameLive.prediction === 'PLAYER' ||
                          gameLive.prediction === 'TIGER'
                          ? '#1e15d2'
                          : '#ffae0098'
                        : '',
                  }"
                >
                  <h1
                    style="font-size: 21px"
                    :class="[
                      'reveal-text m-0',
                      gameLive.prediction === 'BANKER' ||
                      gameLive.prediction === 'DRAGON'
                        ? 'red-reveal'
                        : gameLive.prediction === 'TIE' ||
                          gameLive.prediction === 'TIE'
                        ? 'green-reveal'
                        : 'blue-reveal',
                    ]"
                    v-if="gameLive.prediction != null"
                  >
                    {{ gameLive.prediction }}
                  </h1>

                  <pulse-loader color="#000" size="16px" v-else></pulse-loader>
                </a-card>
              </div>
            </a-col>
          </a-row>
        </a-card>
      </a-collapse-panel>
    </a-collapse>

    <a-row :gutter="24" type="flex" align="stretch" class="w-100">
      <a-col :span="24" :lg="24">
        <a-collapse v-model="activeKey" class="w-100">
          <a-collapse-panel key="1" header="ประวัติการเล่น">
            <a-col
              :span="24"
              :lg="24"
              class="mb-24"
              v-if="gamePath === 'ROULETTE'"
            >
              <CardHistory
                :data="tableData"
                :columns="tableColumns"
                :gameType="gamePath"
              ></CardHistory>
            </a-col>
            <a-col :span="24" :lg="24" class="mb-24" v-else>
              <CardHistory
                :data="tableData"
                :columns="tableColumnsBD"
                :gameType="gamePath"
              ></CardHistory>
            </a-col>
          </a-collapse-panel>
          <a-collapse-panel key="2" header="รูปแบบการเดินเงิน">
            <a-col :span="24" :md="24" class="mb-10">
              <a-card
                :bordered="false"
                style="background-color: rgb(113 77 0) !important"
              >
                <!-- <div class="card-title">
                    <h6>รูปแบบการเดินเงิน</h6>
                  </div> -->
                <div class="card-content">
                  <p>
                    การเดินเงินเป็นการบริหารความเสี่ยงในการลงทุนเพื่อให้สามารถทำกำไรจนถึงเป้าหมายที่กำหนด
                  </p>
                </div>
                <a-col class="p-0">
                  <a-radio-group
                    size="small"
                    button-style="solid"
                    v-model="bot_setting.betMoneyType"
                  >
                    <a-radio-button
                      class="mb-5"
                      v-for="(data, i) in setting[
                        this.$route.params.game.split('-')[0]
                      ].money_system"
                      :key="i"
                      :value="data.value"
                      >{{ data.title }}
                    </a-radio-button>
                  </a-radio-group>
                  <a-col class="mt-10">
                    <p v-if="bot_setting.betMoneyType === 'NORMAL'">
                      การแทงแบบคงที่ตามจำนวนชิปที่ลงไว้
                    </p>

                    <p
                      v-if="
                        bot_setting.betMoneyType === 'MARTINGALE' ||
                        bot_setting.betMoneyType === 'REVERSE_MARTINGALE'
                      "
                    >
                      การแทงทบ ซึ่งในทุก ๆ ครั้งที่มีการแพ้เกิดขึ้น
                      ให้ผู้เล่นทำการวางเงินเดิมพันแทงทบไปเรื่อย ๆ จนกว่าจะชนะ
                    </p>

                    <p v-if="bot_setting.betMoneyType === 'FIBONACCI'">
                      หลักการในการเดินเงินแบบฟิโบนักชีคือ
                      ผู้เล่นจะต้องวางเงินเดิมพันเป็นหน่วยตามลำดับตัวเลขอนุกรม
                      ดังนี้ 0, 1, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89,144, 233,
                      37, 610, 987, 1597, 2584 … อนันต์ในทุก ๆ ครั้งที่มีการแพ้
                      ซึ่งท้ายที่สุดแล้วผู้เล่นจะสามารถทำกำไรได้ 4-10 หน่วย
                    </p>
                    <p v-if="bot_setting.betMoneyType === 'LABOUCHERE'">
                      เมื่อเชี่ยวชาญแล้วสามารถปรับใช้สูตรกับเกมคาสิโนเกือบทุกเกม
                      หลักการเดินเงินแบบลาบูร์แชร์อธิบายง่าย ๆ ว่า
                      ผู้เล่นต้องกำหนดเป้าหมายจำนวนผลกำไรที่ต้องการ
                      จากนั้นนำมาแบ่งเป็นสูตรเดิมพันแต่ละรอบ เช่น ต้องการกำไร
                      1,500 บาท แบ่งเงินทั้งหมดเป็นเงินเดิมพันตามสูตรดังนี้ 100,
                      200, 300, 400, 500
                      จากนั้นวางเดิมพันรอบแรกโดยใช้เงินลำดับแรกบวกกับลำดับสุดท้ายคือ
                      100+500=600 หมายถึง แทงรอบแรก 600 บาท
                    </p>
                  </a-col>
                  <div
                    v-if="bot_setting.betMoneyType === 'MARTINGALE'"
                    class="mt-10"
                  >
                    <a-radio-group
                      size="small"
                      button-style="solid"
                      v-model="init_martingale"
                      @change="createMartingaleData(init_martingale)"
                    >
                      <a-radio-button
                        class="mb-5"
                        v-for="(data, i) in martingale"
                        :key="i"
                        :value="data"
                        >{{ data }}</a-radio-button
                      >
                    </a-radio-group>

                    <a-col
                      :span="8"
                      class="p-0"
                      v-for="(data, i) in init_martingale"
                      :key="i"
                      :value="data"
                    >
                      <a-input
                        type="number"
                        :placeholder="`ไม้ที่ ${i}`"
                        v-model.number="bot_setting.martingaleList[i]"
                      />
                    </a-col>
                  </div>
                  <div
                    v-if="bot_setting.betMoneyType === 'REVERSE_MARTINGALE'"
                    class="mt-10"
                  >
                    <a-radio-group
                      size="small"
                      button-style="solid"
                      v-model="init_martingale"
                      @change="createReverseMartingaleData(init_martingale)"
                    >
                      <a-radio-button
                        class="mb-5"
                        v-for="(data, i) in martingale"
                        :key="i"
                        :value="data"
                        >{{ data }}</a-radio-button
                      >
                    </a-radio-group>

                    <a-col
                      :span="8"
                      class="p-0"
                      v-for="(data, i) in init_martingale"
                      :key="i"
                      :value="data"
                    >
                      <a-input
                        type="number"
                        :placeholder="`ไม้ที่ ${i}`"
                        v-model.number="bot_setting.reverseMartingaleList[i]"
                      />
                    </a-col>
                  </div>
                </a-col>
              </a-card>
            </a-col>
          </a-collapse-panel>
        </a-collapse>
        <a-col :span="24" :md="24" class="mb-10" style="width: 70vw"> </a-col>
      </a-col>
    </a-row>

    <a-row type="flex" :gutter="24">
      <!-- Billing Info Column -->

      <a-col :span="24" :md="24">
        <Loading :loading="false" />
        <div class="d-flex justify-content-end mt-10">
          <a-button
            @click="getSexyGame"
            target="_blank"
            style="
              background-color: #ffae0098;
              border-color: #ffae00;
              border-radius: 4px 4px 0px 0px;
            "
          >
            รีเฟรช
          </a-button>
        </div>

        <div class="iframe-container">
          <iframe
            class="gold-border"
            id="myIframe"
            src="about:blank"
            frameborder="0"
          ></iframe>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Loading from "./components/Loading.vue";
import CardHistory from "@/views/components/CardHistory";
import Meter from "./components/Meter.vue";
import SettingRadioGroup from "./components/setting/SettingRadioGroup.vue";

import settingBaccarat from "./components/setting/json/settingBaccarat.json";
import settingRoulette from "./components/setting/json/settingRoulette.json";
import settingTigerDragon from "./components/setting/json/settingTigerDragon.json";
export default {
  components: {
    Loading,
    PulseLoader,
    CardHistory,
    Meter,
    SettingRadioGroup,
    settingBaccarat,
    settingRoulette,
    settingTigerDragon,
  },
  computed: {},
  mounted() {
    this.gamePath = this.$route.params.game.split("-")[0];
    this.tableId = parseInt(this.$route.params.game.split("-")[1]);
    this.getGameLive();
    this.getSexyGame();
    this.getHistory();
    this.getListServer(this.tableId);
    for (var i = 1; i <= 18; i++) this.martingale.push(i);
    this.bot_setting.martingaleList = new Array(1)
      .fill(0)
      .map((x, idx) => this.bot_setting.betAmount * 2 ** idx);
    this.bot_setting.reverseMartingaleList = new Array(1)
      .fill(0)
      .map((x, idx) => this.bot_setting.betAmount * 2 ** idx);
  },
  data() {
    return {
      activeKey: ["1"],
      activeKey2: ["1"],
      setting: {
        BACCARAT: settingBaccarat,
        ROULETTE: settingRoulette,
        DRAGON_TIGER: settingTigerDragon,
      },
      bot_setting: {
        betMoneyType: "NORMAL",
        betAmount: 10,
        profitTarget: { amount: 0, percent: 0 },
        lossLimit: { amount: 0, percent: 0 },
        martingaleList: [],
        reverseMartingaleList: [],
        labouchereList: [],
        playerBetType: "",
        isAutoWithdraw: false,
        // "RED_BLACK"
      },
      martingale: [],
      init_martingale: 1,
      games: [],
      gamePath: "",
      tableId: 0,
      loginLink: "",
      gameLive: {},
      timer: 0,
      setTimer: false,
      gameRound: null,
      tableData: [],
      tableColumnsBD: [
        {
          title: "รอบที่",
          dataIndex: "gameRound",
        },
        {
          title: "โตีะที่",
          dataIndex: "tableId",
        },

        {
          title: "ฝั่งที่เล่น",
          dataIndex: "prediction",
        },

        {
          title: "ผลการเล่น",
          dataIndex: "isWin",
          scopedSlots: { customRender: "isWin" },
          class: "font-bold text-muted text-sm",
        },
        {
          title: "เวลา",
          dataIndex: "createdAt",

          scopedSlots: { customRender: "createdAt" },
          class: "font-bold text-muted text-sm",
        },
      ],
      tableColumns: [
        {
          title: "รอบที่",
          dataIndex: "gameRound",
        },
        {
          title: "โตีะที่",
          dataIndex: "tableId",
        },

        {
          title: "ฝั่งที่เล่น(หมายเลข)",
          dataIndex: "prediction.number",
        },
        {
          title: "ฝั่งที่เล่น",
          dataIndex: "prediction.playerType",
        },

        {
          title: "ผลการเล่น",
          dataIndex: "isWin",
          scopedSlots: { customRender: "isWin" },
          class: "font-bold text-muted text-sm",
        },
        {
          title: "เวลา",
          dataIndex: "createdAt",

          scopedSlots: { customRender: "createdAt" },
          class: "font-bold text-muted text-sm",
        },
      ],
      winRate: 0,
    };
  },
  methods: {
    next(val) {
      this.$router
        .push({
          path: `/room/${val}`,
          params: {
            botType: val,
          },
        })
        .catch(() => {});
    },
    createMartingaleData(value) {
      this.bot_setting.martingaleList = new Array(value)
        .fill(0)
        .map((x, idx) => this.bot_setting.betAmount * 2 ** idx);
    },
    createReverseMartingaleData(value) {
      this.bot_setting.reverseMartingaleList = new Array(value)
        .fill(0)
        .map((x, idx) => this.bot_setting.betAmount * 2 ** idx);
    },
    getSexyGame() {
      this.$http
        .get(`${process.env.VUE_APP_BACKEND_API_URL}/api/game/sexy`)
        .then(({ data }) => {
          console.log("sexygame", data.loginLink);
          this.loginLink = data.loginLink;
          document.getElementById("myIframe").src = this.loginLink;
        })
        .catch((err) => {
          return err;
        });
    },
    getHistory() {
      this.$http
        .get(
          `${process.env.VUE_APP_BACKEND_API_URL}/api/game/${this.gamePath}/history`
        )
        .then(({ data }) => {
          // this.tableData = data;
          for (var i = 0; i < data.length; i++) {
            if (data[i].tableId === this.tableId) {
              this.tableData.push(data[i]);
            }
          }
          console.log("this.tableData", this.tableData);
        })
        .catch((err) => {
          return err;
        });
    },
    getListServer(tableId) {
      this.$http
        .get(`${process.env.VUE_APP_BACKEND_API_URL}/api/server-bot`)
        .then(({ data }) => {
          let filteredUsers = data.list.filter((val) => {
            return val.tableId === tableId;
          });
          console.log(filteredUsers);
          this.winRate = filteredUsers[0].winRate;
        })
        .catch((err) => {
          return err;
        });
    },

    getGameLive() {
      let temp_positive = 0;
      let temp_negative = 0;
      this.sockets.subscribe(
        `game-${this.gamePath}-${this.tableId}-live`,
        (data) => {
          console.log(data);
          this.gameLive = data;
          if (data.gameState === "RESULTED") {
            this.timer = data.betDurationInSeconds;
            this.gameRound = data.gameRound;
          }
          if (data.gameState === "PLAYING") {
            temp_positive = 0;
            temp_negative -= 1;
            this.setTimer = true;
            this.gameRound = data.gameRound;
          }
          if (temp_positive === 1) {
            this.timer = 0;
            this.setTimer = false;
          }
          if (temp_negative === -1) {
            this.countDownTimer();
          }
        }
      );
    },
    countDownTimer() {
      if (this.timer > 0) {
        setTimeout(() => {
          this.timer -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
  },
};
</script>
<style lang="scss">
div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: yellow;
  padding: 50px;
  font-size: 20px;
}
.ant-collapse {
  background-color: #ffae0098 !important;

  .ant-collapse-header {
    color: white !important;
  }
  .ant-collapse-content {
    background-color: #0000008d !important;
  }
}
.gold-border {
  border: 1px solid #ffae0098 !important;
}

.gold_color {
  background-color: #000000 !important;
  border: 1px solid #ffae0098 !important;
}
#myIframe {
  width: 1px;
  min-width: 100%;
  min-height: 100%;
  border-radius: 0px 12px 12px 12px;
}

.iframe-container {
  position: relative;
  height: 100vh;
  min-height: 100vh;
  background-color: #000000;
  // iframe {
  //   height: 100%;
  //   width: 100%;
  //   left: 0;
  //   top: 0;
  //   position: absolute;
  //   body,
  //   html {
  //     height: 100%;
  //     overflow: hidden;
  //     background: transparent;
  //   }
  // }
}

/* diamond shape */
.glow {
  position: relative;
  animation: glow 2s infinite ease;
}
.glow:before,
.glow:after {
  content: "";
  display: block;
  position: absolute;
}
.glow:before {
  box-shadow: 0 0 2px 3px #ffae0098;
  box-shadow: 1px 1px 0 #ffae0098, 0 0 20px 5px #ffc03798,
    inset 2px 2px 0 #ffae0098;
}
.glow:after {
  animation: sheen 5s infinite;
}

/* animates box shadow glow effect */
@keyframes glow {
  0%,
  100% {
    box-shadow: 1px 1px #ffae0098, 0 0 20px 5px #ffc03798,
      inset 2px 2px #ffcd6098;
  }
  50% {
    box-shadow: 1px 1px #ffae0098, 0 0 20px 5px #ffc03798,
      inset 2px 2px #ffcd6098;
  }
}
/* animates light across diamond */
@keyframes sheen {
  0%,
  100% {
    left: 0;
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  10% {
    left: calc(100% - 4px);
  }
  11%,
  98% {
    left: 100%;
    opacity: 0;
  }
}

:root {
  --delay: 0;
  --duration: 800ms;
  --iterations: 1;
}
/* •·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•· */

.reveal-text,
.reveal-text::after {
  animation-delay: var(--animation-delay, 2s);
  animation-iteration-count: var(--iterations, 1);
  animation-duration: var(--duration, 800ms);
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.reveal-text {
  --animation-delay: var(--delay, 0);
  --animation-duration: var(--duration, 800ms);
  --animation-iterations: var(--iterations, 1);
  position: relative;
  font-size: 4vw;
  animation-name: clip-text;
  white-space: nowrap;
  cursor: default;
}

.red-reveal {
  &::after {
    content: "";
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d21515;
    transform: scaleX(0);
    transform-origin: 0 50%;
    pointer-events: none;
    animation-name: text-revealer;
  }
}
.blue-reveal {
  &::after {
    content: "";
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #1e15d2;
    transform: scaleX(0);
    transform-origin: 0 50%;
    pointer-events: none;
    animation-name: text-revealer;
  }
}
.green-reveal {
  &::after {
    content: "";
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #079300;
    transform: scaleX(0);
    transform-origin: 0 50%;
    pointer-events: none;
    animation-name: text-revealer;
  }
}

.animated-border-box,
.animated-border-box-glow {
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 0;
  border-radius: 12px;
  // top: -2px;
  // left: 10px;
}

.animated-border-box-glow {
  overflow: hidden;
  /* Glow Blur */
  filter: blur(20px);
}

.animated-border-box:before,
.animated-border-box-glow:before {
  content: "";
  z-index: -2;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  position: absolute;
  width: 99999px;
  height: 99999px;
  background-repeat: no-repeat;
  background-position: 0 0;
  /*border color, change middle color*/
  background-image: conic-gradient(
    rgba(0, 0, 0, 0),
    #ffae0098,
    rgba(0, 0, 0, 0) 25%
  );
  /* change speed here */
  animation: rotate 4s linear infinite;
}

.animated-border-box:after {
  content: "";
  position: absolute;
  z-index: -1;
  /* border width */
  left: 5px;
  top: 5px;
  /* double the px from the border width left */
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  /*bg color*/
  background: #292a2e;
  /*box border radius*/
  border-radius: 7px;
}

@keyframes rotate {
  100% {
    transform: translate(-50%, -50%) rotate(1turn);
  }
}

@keyframes clip-text {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes text-revealer {
  0%,
  50% {
    transform-origin: 0 50%;
  }

  60%,
  100% {
    transform-origin: 100% 50%;
  }

  60% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}
</style>
